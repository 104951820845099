@import url("https://fonts.googleapis.com/css2?family=Inter&family=Lato:ital,wght@0,300;0,400;0,700;1,400&family=Outfit:wght@200&family=Roboto&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  padding: 2rem;
}

.image-gradient {
  background: linear-gradient(
    1deg,
    rgba(0, 129, 248, 0.07) -25.89%,
    rgba(67, 105, 253, 0) 109.58%
  );
}

/* Phone number input custom styling */
.react-international-phone-input {
  height: 44px;
}

.react-international-phone-input-container .react-international-phone-input {
  width: 100%;
  height: 44px !important;

  --tw-border-opacity: 1;
  border-color: rgb(198 208 218 / var(--tw-border-opacity));
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  padding: 0 12px 0 4px;
  height: 44px;

  --tw-border-opacity: 1;
  border-color: rgb(198 208 218 / var(--tw-border-opacity));
}

/* Carousel custom styling */
.react-multi-carousel-dot-list {
  position: relative !important;
  margin-top: 1rem !important;
}

.react-multi-carousel-dot button {
  border: none !important;
  background-color: #c6d0da;
  width: 9px !important;
  height: 9px !important;
}

.react-multi-carousel-dot-list {
  gap: 1rem !important;
}
