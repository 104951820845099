.valueSwiper .swiper-pagination {
  bottom: 10px; /* Adjust this value as needed */
}

.valueSwiper .swiper-button-next,
.valueSwiper .swiper-button-prev {
  top: 50%; /* Adjust this value as needed */
  transform: translateY(-50%);
  color: #000; /* Adjust the color as needed */
}

.valueSwiper .swiper-button-next {
  right: 10px; /* Adjust this value as needed */
}

.valueSwiper .swiper-button-prev {
  left: 10px; /* Adjust this value as needed */
}

.valueSwiper {
  padding-bottom: 2rem;
}
